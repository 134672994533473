import React, { useEffect } from 'react';
import { BsCheck } from 'react-icons/bs';
import { Control } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import helpImg from '../../assets/images/help.png';
import helpIcon from '../../assets/images/icons/help.svg';
import {
  ErrorField, InputText, StyledCreatable, StyledSelect,
} from '../../lib/HooksFormFields';
import { IOrder, IOrderList, RequiredRule } from '../../types/order.types';
import styles from './OrdersForms.module.scss';
import { IOption } from '../../lib/HooksFormFields/StyledSelect';
import { useAppSelector } from '../../hooks/useRedux';

export default function Infos({
  errors,
  control,
  requiredRule,
  onSubmit,
  watchForm,
  setValue,
  lists,
  url,
}:{
  errors: any
  control: Control<IOrder, any>
  requiredRule: RequiredRule,
  onSubmit: any,
  watchForm: any,
  setValue: any,
  lists: IOrderList | null,
  url:string,
}) {
  const navigate = useNavigate();
  const emailsOptions = lists?.emails?.map((e: any) => ({ value: e.email, label: e.email })) || [];
  const {
    order,
  } = useAppSelector((state: any) => state.orderReducer);
  const handleSubmitStep = (step : string) => {
    if (step === '2') {
      if (watchForm.title && watchForm.eventType && watchForm.structure && watchForm.emails.length > 0) {
        navigate(`${url}?step=${step}`);
      } else {
        onSubmit(watchForm);
      }
    }
  };
  useEffect(() => {
    if (watchForm?.structure?.value && !order) {
      console.log(watchForm.structure);
      const emails = lists?.emails?.filter(
        (e: any) => e.structures.find((s: string) => s === watchForm.structure?.value),
      ).map((e: any) => ({ value: e.email, label: e.email })) || [];
      setValue('emails', emails);
    }
  }, [watchForm.structure]);
  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.public}>
          <label>Brief</label>
          <button
            type="button"
            onClick={() => setValue('isPublic', false)}
          >
            <div className={`${styles.check} ${!watchForm.isPublic ? styles.active : ''}`}>
              <BsCheck />
            </div>
            Privé
          </button>
          <button
            type="button"
            onClick={() => setValue('isPublic', true)}
          >
            <div className={`${styles.check} ${watchForm.isPublic ? styles.active : ''}`}>
              <BsCheck />
            </div>
            Public
          </button>
        </div>
        <div className={styles['container-field']}>
          <InputText
            name="title"
            className="primary"
            control={control}
            rules={requiredRule}
            label="Titre du brief"
            placeholder="titre..."
          />
          {errors?.title && <ErrorField message={errors.title.message} />}
        </div>

        <div className={styles['container-field']}>
          <StyledSelect
            name="eventType"
            control={control}
            rules={requiredRule}
            label="Type d’évenement"
            placeholder="Séléctionner..."
            options={lists?.events || []}
          />
          {errors.eventType && <ErrorField message={errors.eventType.message} />}
        </div>

        <div className={styles['container-field']}>
          <StyledSelect
            name="structure"
            control={control}
            rules={requiredRule}
            label="Établissement"
            placeholder="Séléctionner..."
            options={lists?.structures || []}
          />
          {errors.structure && <ErrorField message={errors.structure.message} />}
        </div>

        <div className={styles['container-field']}>
          {emailsOptions.length > 0
          && (
          <StyledCreatable
            name="emails"
            control={control}
            onChange={(opt: IOption[]) => setValue('emails', opt)}
            createOption={(opt: IOption) => setValue('emails', [...emailsOptions, opt])}
            rules={requiredRule}
            isMulti
            label="Mail des personnes à notifier"
            placeholder="Ajouter des emails..."
            options={emailsOptions || []}
          />
          )}
          {errors?.emails?.message && <ErrorField message={errors.emails.message} />}
        </div>

        <div className={styles['btn-container']}>
          <button
            className={`${styles.btn} ${styles.primary}`}
            type="button"
            onClick={() => handleSubmitStep('2')}
          >
            Suivant
          </button>
        </div>
      </div>
      <div className={styles.help}>
        <img src={helpImg} alt="help" />
        <div className={styles.title}>
          <img src={helpIcon} alt="help-icon" />
          <h2>Aide</h2>
        </div>
        <ul>
          <li>
            Si un brief est noté public,
            <br />
            le résultat apparaitra dans le folio de la plateforme.
          </li>

          <li>
            Anticipez vos demandes :
            {' '}
            <br />
            <br />
            La date limite de remise correspond à la diffusion de votre communication
            <br />
            (publication sur les réseaux, affichages en interne…)
            <br />
            Donc 1 à 2 semaines avant l’évènement.
            <br />
            Vous disposez d’un délai minimum de 3 semaines,
            <br />
            le calendrier étant bloqué automatiquement.
            <br />
            <br />
            Exemple :
            {' '}
            <i>
              Noel 25 déc. = Date limite de la remise : 18 déc.
              <br />
              = envoie du brief au plus tard le 26 novembre
            </i>
          </li>

        </ul>
      </div>
    </div>
  );
}
